import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react"
import * as React from "react"
import { PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FaGlobe, FaMapMarkedAlt, FaPhone } from "react-icons/fa"
import { IconType } from "react-icons"

import Layout from "../components/layout"
import ContactForm from "../components/contactForm"
import { Hours } from "../data/hours"
import BlockHeader from "../components/blockHeader"
import { getWeekdaySummary, getWeeklySummary } from "../utils/timeUtils"
import SEO from "../components/CoreUI/SEO"

function UniformBox(props: BoxProps): JSX.Element {
  return <Box maxW="95vw" mx="auto" px={4} py={1} w="800px" {...props} />
}

interface ContactMethodIconProps {
  icon: IconType
}

function ContactMethodIcon({ icon }: ContactMethodIconProps): JSX.Element {
  return (
    <Icon
      as={icon}
      bg="red.500"
      boxSize="30px"
      color="white"
      mr="6px"
      p="6px"
      rounded="full"
    />
  )
}

function ContactPage({ location }: PageProps): JSX.Element {
  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Want to talk about your home building plans? We’re just a call, email, or cup of coffee away.",
          image: `${location.origin}/sundog-team-2022-630x205.png`,
          imageAlt: `The Sundog Homes team`,
        }}
        title="Contact Us | Sundog Homes"
        url={location.href}
      />
      <Box py={16} w="100vw">
        <Box maxW="100vw" mb={-1} mx="auto" px={0} w="1200px">
          <StaticImage
            alt="The Sundog Homes team"
            backgroundColor="transparent"
            layout="fullWidth"
            placeholder="blurred"
            src="../images/sundog-team-2022.png"
            style={{ height: `100%`, width: `100%` }}
            transformOptions={{ fit: `cover` }}
          />
        </Box>
        <UniformBox bg="white" pt={6} roundedTop="lg">
          <Flex align="center" flexDir="column">
            <BlockHeader mb={2} mt={4}>
              Contact Us
            </BlockHeader>
            <Text
              fontFamily="heading"
              fontSize={{ base: `3xl`, sm: `5xl` }}
              fontWeight="bold"
            >
              We're here to help.
            </Text>
            <Text fontSize="xl" mt={4}>
              When you’re ready to build your custom home, we’ll be here to
              help. Contact us by phone, email, through our social media
              channels, or just stop by the office for a cup of coffee and a
              chat. You can also fill out the form below for more information.
              We look forward to guiding you on your homebuilding journey.
            </Text>
          </Flex>
        </UniformBox>
        <UniformBox bg="white" py={6}>
          <Flex
            align={{ base: `center`, sm: `start` }}
            bg="gray.50"
            flexDir="row"
            flexWrap="wrap"
            justify="start"
            px={{ base: 0, md: 6 }}
            py={8}
            rounded="md"
            textAlign={{ base: `center`, md: `left` }}
            w="100%"
          >
            <Box mt={4} mx="auto" w={{ base: `100%`, md: `49%` }}>
              <Text color="red.500" fontSize="xl" fontWeight="extrabold" mb={1}>
                <ContactMethodIcon icon={FaPhone} /> By Phone:
              </Text>
              <Text
                fontSize="lg"
                fontWeight="semibold"
                pl={{ base: 0, md: `42px` }}
              >
                <Link
                  href="tel:8287745720"
                  id="click-to-call"
                  textDecoration="underline"
                  _hover={{ color: `red.500` }}
                >
                  (828) 774-5720
                </Link>
              </Text>
              {getWeekdaySummary(Hours, `%dd1 - %dd2,`).map((line, index) => (
                <Flex
                  fontSize="lg"
                  fontWeight="semibold"
                  justify={{ base: `space-evenly`, md: `start` }}
                  key={index}
                  mx={{ base: `auto`, md: 0 }}
                  pl={{ base: 0, md: `42px` }}
                  w={{ base: `200px`, md: `auto` }}
                >
                  <Text mr={{ base: 0, md: 1 }}>{line[0]}</Text>
                  <Text>{line[1]}</Text>
                </Flex>
              ))}
            </Box>
            <Box
              mt={{ base: 6, md: 4 }}
              mx="auto"
              w={{ base: `100%`, md: `49%` }}
            >
              <Text color="red.500" fontSize="xl" fontWeight="extrabold" mb={1}>
                <ContactMethodIcon icon={FaGlobe} /> Online:
              </Text>
              <Text
                fontSize="lg"
                fontWeight="semibold"
                pl={{ base: 0, md: `42px` }}
              >
                <Link
                  href="mailto:contact@sundoghomes.com"
                  textDecoration="underline"
                  _hover={{ color: `red.500` }}
                >
                  info@sundoghomes.com
                </Link>
                <br />
                <Link
                  href="https://www.facebook.com/sundoghomes/"
                  textDecoration="underline"
                  _hover={{ color: `red.500` }}
                >
                  Our Facebook Page
                </Link>
              </Text>
            </Box>
            <Box mt={6} mx="auto" w={{ base: `100%` }}>
              <Text color="red.500" fontSize="xl" fontWeight="extrabold" mb={1}>
                <ContactMethodIcon icon={FaMapMarkedAlt} /> In Person:
              </Text>
              <Text
                fontSize="lg"
                fontWeight="semibold"
                pl={{ base: 0, md: `42px` }}
              >
                18 Regent Park Blvd, Suite C
                <br />
                Asheville, NC 28806
              </Text>
              {getWeeklySummary(Hours, `%dd1 - %dd2, `).map((line, index) => (
                <Flex
                  fontSize="lg"
                  fontWeight="semibold"
                  justify={{ base: `space-evenly`, md: `start` }}
                  key={index}
                  mx={{ base: `auto`, md: 0 }}
                  pl={{ base: 0, md: `42px` }}
                  w={{ base: `200px`, md: `auto` }}
                >
                  <Text mr={{ base: 0, md: 1 }}>{line[0]}</Text>
                  <Text>{line[1]}</Text>
                </Flex>
              ))}
              <Link
                color="red.500"
                href="https://goo.gl/maps/CFnfe2kehVXTV2gp7"
                fontSize="md"
                fontWeight="normal"
                pl={{ base: 0, md: `42px` }}
              >
                View in Google Maps
              </Link>
            </Box>
          </Flex>

          <Box id="message" maxW="100%" mt={8} mx="auto" w="100%">
            <Heading as="h2" textAlign="center">
              Send Us a Message
            </Heading>
            <Text fontSize="xl" mb={8} mt={4} mx="auto" w="100%">
              Questions and comments are always appreciated, and we pride
              ourselves on having the fastest response time in town.
            </Text>
            <Box
              border={{ base: `1px`, md: 0 }}
              borderColor="gray.200"
              mx="auto"
              px={{ base: 4, md: 0 }}
              py={8}
              rounded="lg"
              w="100%"
            >
              <Box maxW="100%" mx="auto" width={{ base: `400px`, md: `100%` }}>
                <ContactForm
                  context={[]}
                  eventLabel="General Contact"
                  includeTextArea
                />
              </Box>
            </Box>
          </Box>
        </UniformBox>
      </Box>
    </Layout>
  )
}

export default ContactPage
